import { createContext, useEffect, useState, useCallback } from "react";
import { useRequest } from "../../Hooks/useRequest";
import VisualizationArea from "./VisualizationArea";
import loadingSvg from "./smartneuro.svg";
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import * as MdIcons from "react-icons/md";
import { useAuthProvider } from "../../Providers/AuthProvider";
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider";
import { hotkeysAtom, loadHotkeysFromConfigs } from "../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { currentPatientFileInfoAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/PatientFile";
import { activeAnnotationSessionAtom, annotationGroupsAtom, annotationsAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useOnMount } from "../../Hooks/useOnMount";
import { layoutGroupsAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { pageManagerRegistry } from "../../Pages/Data/Visualize/DataReview/Data/PageManagerRegistry";
import { viewScaleRegistry } from "../../Pages/Data/Visualize/DataReview/Data/ViewScaleRegistry";
import { useModalProvider } from '../../Providers/ModalProvider';
import { utcToZonedTime } from "date-fns-tz"
import { MobergButton, MobergButtonShape } from "../../Components/MobergButton/MobergButton";
import { MobergIconSize } from "../../Components/MobergIcon/MobergIcon";
import { useAnnotationService } from "../../Hooks/useAnnotationService";
import { eegMontagesAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/EEGMontage";
import { fileScaleRegistry } from "../../Pages/Data/Visualize/DataReview/Data/FileScaleRegistry";
import { useEEGMontageService } from "../../Hooks/useEEGMontageService";
import { DataSource } from "../../Pages/Data/Visualize/DataReview/Types/DataSource";
import { useBackendLinksProvider } from "../../Providers/BackendLinksProvider";
import { Deployment, useEnvironmentVariablesProvider } from "../../Providers/EnvironmentVariablesProvider";
import { usePrefetchData } from "../../Pages/Data/Visualize/DataReview/Hooks/usePrefetchData"
import { useModalitiesProvider } from "../../Providers/ModalitiesProvider";
import { useSocketProvider } from "../../Providers/SocketProvider";

export const VisualizationContext = createContext(null)

const prefetchId = Math.floor(Math.random() * 10000)

export function Visualization(props) {
	const resetAnnotations = useResetRecoilState(annotationsAtom)
	const workspacesProvider = useWorkspacesProvider()
	const authProvider = useAuthProvider()
	const endpointProvider = useEndpointProvider()
	const { setAllPatientModalities } = useModalitiesProvider()
	const [, setPatientFileInfo] = useRecoilState(currentPatientFileInfoAtom)
	const setAnnotationGroups = useSetRecoilState(annotationGroupsAtom)
	const setActiveAnnotationSession = useSetRecoilState(activeAnnotationSessionAtom)
	const [layoutsLoaded, setLayoutLoaded] = useState(false)
	const setLayoutGroups = useSetRecoilState(layoutGroupsAtom)
	const [eegMontages, setEEGMontages] = useRecoilState(eegMontagesAtom)
	const [eegMontageMapping, setEEGMontageMapping] = useState()
	const [admissionInfo, setAdmissionInfo] = useState()
	const eegMontagesLoaded = eegMontages.length > 0
	const { createAnnotationGroup } = useAnnotationService()
	const { deserializeQueryMontage } = useEEGMontageService()
	const { LINKS } = useBackendLinksProvider()
	const { closeDataQuerySocketConnections } = useSocketProvider()
	const { deployment } = useEnvironmentVariablesProvider()

	const isDevelopment = deployment === Deployment.DEVELOPMENT
	const prefetchDisabled = deployment === Deployment.CLINICAL
	const isDemo = workspacesProvider.selectedWorkspace === "DEMO"

	const { patientID, siteName, patient_id, selectedFile, onClose, dataObjectID } = props

	const [ready, setReady] = useState(false)

	const { close } = useModalProvider()
	const { prefetchMessage, startPrefetch, skipPrefetch, stopPrefetch, prefetchIsFinished, enoughSpace } = usePrefetchData({
		prefetchId, 
		dataObjectId: dataObjectID, 
		patientId: patientID,
		fileToPreload: props.selectedFile
	})


	function goBack() {
		stopPrefetch()
		handleCloseVisualization()
		close()
	}

	function handleCloseVisualization() {
		// Memory Cleanup
		pageManagerRegistry.clear()
		viewScaleRegistry.clear()
		fileScaleRegistry.clear()

		// Socket Cleanup
		closeDataQuerySocketConnections()

		// Additional effects from VisualizePatient
		onClose()
	}

	const [fileExists, fileExistsLoaded, getFileExists] = useRequest(
		`${LINKS.DATA.PROFILING.CHECK_HDF5_FILE.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': "application/json",
				'Authorization': authProvider.token,
			},
			body: JSON.stringify({
				patient_id: patient_id,
				study_id: workspacesProvider.selectedWorkspace
			})
		})
	)

	const [modalities, modalitiesLoaded, getModalitiesUnion] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MODALITIES_UNION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json',
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				patient_uids: [patient_uid],
			})
		})
	)

	const [patientModalities, patientModalitiesLoaded, getModalitiesIntersection] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MODALITIES_INTERSECTION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json',
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				patient_uids: [patient_uid],
			})
		})
	)

	const [medications, medicationsLoaded, getMedicationsIntersection] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MEDICATION_INTERSECTION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json',
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				study_ids: [workspacesProvider.selectedWorkspace],
				patient_uids: [patient_uid],
			})
		})
	)

	const [hotkeysLoaded, setHotkeysLoaded] = useState(false)
	const [hotkeys, setHotkeys] = useRecoilState(hotkeysAtom)


	useOnMount(() => {
		const body = {
			data_object_id: dataObjectID
		}

		endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS, body)
			.then(annotationGroups => {
				setAnnotationGroups(annotationGroups)

				if (annotationGroups.length > 0) {
					setActiveAnnotationSession(annotationGroups[0])
				} else {
					createAnnotationGroup("Default Session", dataObjectID).then(() => {
						endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS, body)
							.then(annotationGroups => {
								setAnnotationGroups(annotationGroups)
								if (annotationGroups.length > 0) {
									setActiveAnnotationSession(annotationGroups[0])
								}
							})
					})
				}
			})
			.catch(error => alert(error))
	})

	useEffect(() => {
		endpointProvider.post(LINKS.ACCOUNT.GET_KEYBOARD_SHORTCUTS, {})
			.then(hotkeyConfigs => {
				setHotkeys(loadHotkeysFromConfigs(hotkeyConfigs))
				setHotkeysLoaded(true)
			})
			.catch(e => console.error(e))
	}, [endpointProvider, setHotkeys])

	const updateHotkeyConfig = useCallback((newHotKeyConfigs, callback) => {
		const res = newHotKeyConfigs.map((config) => {
			const newConfig = { id: config.id, action: config.action, triggers: config.triggers }
			if (config.data) { newConfig.data = config.data }
			return newConfig
		})
		endpointProvider.post(LINKS.ACCOUNT.UPDATE_KEYBOARD_SHORTCUTS, { "configs": res }).then(
			(s) => { callback(s) }).catch(e => { console.error(e) })
	}, [endpointProvider])

	const resetHotkeyConfig = useCallback((callback) => {
		endpointProvider.post(LINKS.ACCOUNT.RESET_KEYBOARD_SHORTCUTS, {}).then(
			(s) => { callback(s) }).catch(e => { console.error(e) })
	}, [endpointProvider])

	const [startEndTimestamps, startEndTimestampsLoaded, getStartEndTimestamps] = useRequest(
		`${LINKS.DATA.PROFILING.GET_PATIENT_FILE_START_END_TIMESTAMPS.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': "application/json",
				'Authorization': authProvider.token,
			},
			body: JSON.stringify({
				patient_ids: [patient_id],
				study_ids: [workspacesProvider.selectedWorkspace]
			})
		})
	)


	const [USER_INFO, userInfoLoaded, getUserInfo] = useRequest(
		`${LINKS.ACCOUNT.GET_CURRENT_USER.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		() => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': "application/json",
				'Authorization': authProvider.token,
			},
			body: JSON.stringify({
				study_id: ''
			})
		})
	)

	const handleDownloadHDF5File = props.handleDownloadHDF5File

	const getLayouts = () => {
		endpointProvider.post(LINKS.DATA.PROFILING.GET_LAYOUTS)
			.then(layoutGroups => {
				setLayoutGroups(layoutGroups)
				setLayoutLoaded(true)
			})
			.catch(error => alert("Error with getting layouts: " + error))
	}

	const getEEGMontages = () => {
		endpointProvider.post(LINKS.VISUALIZE.EEG.GET_EEG_MONTAGES)
			.then(eegMontages => {
				setEEGMontages(eegMontages.map(deserializeQueryMontage))
			})
			.catch(error => alert("Error with getting EEG Montages: " + error))
	}

	const getEEGMontageMapping = () => {
		endpointProvider.post(LINKS.VISUALIZE.EEG.GET_MONTAGE_MAPPING, { data_object_id: dataObjectID })
			.then(mapping => setEEGMontageMapping(new Map(Object.entries(mapping))))
			.catch(error => alert("Error with getting EEG Montage Mapping: " + error))
	}

	const getAdmissionInfo = () => {
		const body = {
			"patient_id": patientID
		}

		endpointProvider.post(LINKS.VISUALIZE.GET_ADMISSION_INFO, body).then(result => setAdmissionInfo(result))
	}

    useOnMount(() => {
		getFileExists(props.patientID).catch(err => console.error(err))
		getModalitiesUnion(props.patientID).then(modalities => setAllPatientModalities(modalities)).catch(err => console.error(err))
		getModalitiesIntersection(props.patientID).catch(err => console.error(err))
		getMedicationsIntersection(props.patientID).catch(err => console.error(err))
		getStartEndTimestamps(props.patientID).catch(err => console.error(err))
		getUserInfo().catch(err => console.error(err))
		resetAnnotations()
		getLayouts()
		getEEGMontages()
		getEEGMontageMapping()
		getAdmissionInfo()
    })

	const validStartEndTimestampsResponse = (timestamps, file) => {
		return (file in timestamps) && ('monitoring_start_time' in timestamps[file]) && ('monitoring_end_time' in timestamps[file])
	}

	const isReadyToPrefetch =
		modalitiesLoaded &&
		patientModalitiesLoaded &&
		medicationsLoaded &&
		userInfoLoaded &&
		hotkeysLoaded &&
		dataObjectID &&
		patientID &&
		layoutsLoaded &&
		eegMontagesLoaded &&
		eegMontageMapping &&
		workspacesProvider.selectedWorkspace && 
		workspacesProvider.selectedWorkspace.length > 0 &&
		startEndTimestampsLoaded && 
		validStartEndTimestampsResponse(startEndTimestamps, selectedFile.obj_name) &&
		selectedFile.obj_name && 
		selectedFile.obj_name.length > 0 &&
		admissionInfo && 
		fileExistsLoaded && 
		fileExists.exists &&
		enoughSpace

	// TODO: this staged loading needs a complete overhaul because it regularly causes many bugs
	useEffect(() => {
		if (isReadyToPrefetch && !prefetchIsFinished) {
			startPrefetch()
		}

		if (!ready && isReadyToPrefetch && prefetchIsFinished) {
			let { monitoring_start_time, monitoring_end_time, timezone } = startEndTimestamps[selectedFile.obj_name]

			if (!timezone) {
				timezone = "America/New_York"
			}

			setPatientFileInfo({
				fileStartDate: utcToZonedTime(new Date(monitoring_start_time), timezone),
				fileEndDate: utcToZonedTime(new Date(monitoring_end_time), timezone),
				patientId: patientID,
				dataSourceMap: new Map([
					[DataSource.CURRENT_PATIENT, dataObjectID],
					[DataSource.CPPOPT_ANALYSIS, 999999],
				]),
				patientModalities: patientModalities,
				montageMapping: eegMontageMapping,
				timeZone: timezone,
				isAdmitted: admissionInfo.is_admitted
			})

			setReady(true)
		}
	})


    return ready 
		?
		<VisualizationContext.Provider value={{patientID, selectedFile, siteName, patient_id, modalities, patientModalities, medications, hotkeys, setHotkeys, hotkeysLoaded, layoutsLoaded,
			resetHotkeyConfig, updateHotkeyConfig, startEndTimestamps: startEndTimestamps[selectedFile.obj_name], closeVisualization: handleCloseVisualization, handleDownloadHDF5File, 
			dataObjectID
		}}>
			<VisualizationArea />
		</VisualizationContext.Provider>
		:
		<div style={{ display: "flex", width: "100vw", height: "100vh", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF" }}>
			<div style={{ flex: 0, display: "flex", width: "100%", alignItems: "center", justifyContent: "right", padding: "20px" }}>
				<MobergButton onClick={goBack} shape={MobergButtonShape.SQUARE}>
					<MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
				</MobergButton>
			</div>

			<div style={{ flex: 1 }}>
				<div style={{ marginBottom: "100px" }}>
					<h1 style={{ marginBottom: "20px" }}>{selectedFile.obj_name}</h1>
					<h5>{siteName} </h5>
					<h5>Patient: {patient_id} </h5>
				</div>

				<div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", background: "#FFFFFF" }}>
						{fileExistsLoaded ?
							fileExists.exists ?
								<>

									<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF", height: "100%" }}>
										<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
										<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Loading...</h2>
									</div>
									<div style={{ flex: 1 }}>
										<p>{modalitiesLoaded && patientModalitiesLoaded ? <Check /> : <Close />} Modalities</p>
										<p>{medicationsLoaded ? <Check /> : <Close />} Medications</p>
										<p>{userInfoLoaded ? <Check /> : <Close />} User Info</p>
										<p>{hotkeysLoaded ? <Check /> : <Close />} Hotkeys</p>
										<p>{layoutsLoaded ? <Check /> : <Close />} Layouts</p>
										<p>{eegMontagesLoaded && eegMontageMapping ? <Check /> : <Close />} EEG Montages</p>
										<p>{startEndTimestampsLoaded ? <Check /> : <Close />} File Start and End Timestamps</p>
										<p>{workspacesProvider.selectedWorkspace ? <Check /> : <Close />} Workspace</p>
										<p>{selectedFile.obj_name ? <Check /> : <Close />} Patient File</p>
										{!prefetchDisabled && (<p>{prefetchIsFinished ? <Check /> : <Close />} {prefetchMessage} </p>)}
										<p style={{
											color: "#1890ff", cursor: "pointer", size: '22',
											visibility: (modalitiesLoaded && patientModalitiesLoaded && medicationsLoaded && userInfoLoaded &&
												hotkeysLoaded && layoutsLoaded && eegMontagesLoaded && startEndTimestampsLoaded && workspacesProvider && ( isDevelopment || isDemo)  ) ? 'visible' : 'hidden'
										}}
											onClick={skipPrefetch}>Skip Prefetch Data</p>

									</div>
								</>
								:
								<div style={{ textAlign: "center" }}>
									<p>File still converting</p>
									<p style={{ color: "#1890ff", cursor: "pointer" }} onClick={handleCloseVisualization}>Go Back</p>
								</div>
							:
							<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF", height: "100%" }}>
								<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
								<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Checking file...</h2>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
}

const Check = () => <MdIcons.MdCheck color={"green"} size={18} />
const Close = () => <MdIcons.MdClose color={"red"} size={18} />