import { Socket } from "socket.io-client"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { TimeSeriesPageManager } from "./TimeSeriesPageManager"

interface PageManager extends TimeSeriesPageManager<any> {}

type PageManagerEntry = {
	pageManager: PageManager
	componentId: string
}

// Holds references to page managers for Data Review.
// This allows us to keep data in memory when switching tabs.
class PageManagerRegistry {
	private registry: Map<string, PageManagerEntry> = new Map()

	get<T extends TimeSeriesPageManager<any, any>>(key: LayoutWindowId, componentId: string, defaultValue: T, getDataQuerySocket: (id?: string) => Socket): T {
		const entry = this.registry.get(this.mapKey(key))

		// Checks to see if the manager is the exact same type as the default value.
		// This allows us to free managers correctly by using the visualization Id as a key, 
		// but also prevent different types of managers from being attached to visualizations that they don't belong to.
		if (entry?.pageManager && entry?.pageManager.constructor.name === defaultValue.constructor.name && entry.componentId === componentId) {
			return entry.pageManager as T
		}

		defaultValue.setDataQuerySocketAccessor(getDataQuerySocket)

		this.registry.set(this.mapKey(key), {componentId, pageManager: defaultValue })

		return defaultValue
	}

	set<T extends TimeSeriesPageManager<any>>(key: LayoutWindowId, componentId: string, pageManager: T, ) {
		this.registry.set(this.mapKey(key), { componentId, pageManager })
	}

	// Allows all memory to be cleaned up by the garbage collector.
	clear = () => {
		this.registry.forEach(entry => {
			entry.pageManager.unloadAllPages()
			entry.pageManager.isLoadingDisabled = true
		})
		this.registry.clear()
	}

	// Using Objects as Map keys can result in undefined behavior because it uses reference equality for comparison.
	// So, we turn the object into a string which can be checked for equality.
	private mapKey = (key: LayoutWindowId): string => `${key.layoutId}-${key.windowId}`
}

export const pageManagerRegistry = new PageManagerRegistry()
